.outside-footer {
    --footer-open-percentage: 75%;
    transform: translateY(var(--footer-open-percentage));
    background: rgba(23, 23, 23, 0.98);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  bottom: 0;
  margin: 0;
  padding-top: 0.5em;
  left: 0;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  font-family: "Hiragino Kaku Gothic ProN W3", sans-serif;
}

.filter-button {
  background: none;
  border: none;
  height: 12em;
  width: 12em;
}

.filter-button:hover {
  transform: scale(1.1);
}

.filter-button-image {
  border: 3px solid white;
  border-radius: 50%;
}

.filter-button-label {
  font-size: 2em;
  padding-left: 0.5em;
}

.inside-footer {
  color: white;
  padding-top: 0.1em;
  padding-bottom: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  bottom: 0;
  margin: 0;
  left: 0;
  width: 100%;
}

.urls {
  color: whitesmoke;
  font-size: 2em
}

.urls:hover {
  color: darkgray;
}

.buttons-container {
  margin-left: 0.4em;
  gap: 10em;
  display: flex;
  flex-direction: row;
}

.footer-arrow {
  filter: invert(1);
  width: 6em;
  height: 6em;
  transform: rotate(-90deg);
  opacity: 0;
}

.footer-arrow-container:hover {
  transform: scale(1.1);
}

.footer-arrow.open {
  animation: rotateArrowDown 0.5s forwards;
}

.footer-arrow.close {
  animation: rotateArrowUp 0.5s forwards;
}

@keyframes rotateArrowDown {
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(90deg);
  }
}

@keyframes rotateArrowUp {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(-90deg);
  }
}

@keyframes translateFooterDown {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(var(--footer-open-percentage));
  }
}

@keyframes translateFooterUp {
  from {
    transform: translateY(var(--footer-open-percentage));
  }
  to {
    transform: translateY(0%);
  }
}

.outside-footer.close {
  animation: translateFooterDown 0.5s forwards;
}

.outside-footer.open {
  animation: translateFooterUp 0.5s forwards;
}

.footer-line {
  width: 80%;
  height: 1px;
  background-color: #CCC;
  margin: 1.5em auto;
}

@media (min-width: 1000px) {
    .outside-footer {
        --footer-open-percentage: 80%;
    }

  .filter-button-label {
    font-size: 1em;
  }

  .filter-button {
    height: 6em;
    width: 6em;
  }

  .footer-arrow {
    width: 2em;
    height: 2em;
  }

  .footer-line {
    width: 500px;
    margin: 0.5em auto;
  }

  .urls {
    font-size: 1em;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .outside-footer {
        --footer-open-percentage: 79%;
    }

  .footer-arrow {
    width: 2em;
    height: 2em;
  }

  .filter-button-label {
    font-size: 1em;
  }
  
  .filter-button {
    height: 6em;
    width: 6em;
  }
  
  .footer-line {
    width: 50%;
  }

  .urls {
    font-size: 1em;
  }

  .footer-line {
    margin: 0.5em auto;
  }
}

.line {
  position: absolute;
  background-color: rgb(255, 255, 255);
  transition: transform 0.3s ease;
  color: white;
}

.horizontal {
  width: 100%;
  height: 5px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.vertical {
  width: 5px;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.vertical.open {
  transform: translateX(-50%) rotate(90deg);
}