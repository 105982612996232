.open-mark-container {
  position: absolute;
  text-align: left;
  font-size: 3em;
  margin-left: -0.6em;
  margin-top: -0.5em;
  overflow: visible;
  z-index: 9999;
}

@media (min-width: 1000px) {
  .open-mark-container {
    font-size: 1.1em;
  }
}

@keyframes rotateElement {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}

@keyframes rotateUp {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(-90deg);
  }
}

@keyframes rotateDown {
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(90deg);
  }
}

.rotating-element {
  animation: rotateElement 0.5s forwards;
  animation-delay: 0.2s;
}

.rotating-up {
  animation: rotateUp 0.5s forwards;
}

.rotating-down {
  animation: rotateDown 0.5s forwards;
}
