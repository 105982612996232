.outer-project {
  text-align: center;
  overflow: hidden;
  height: auto;
  border-top: 5px solid white;
  -webkit-touch-callout: none;
  border-radius: 0px;
}

.inner-project {
  position: relative;
  opacity: 0;
  display: block;
}

.inner-project.fade-in {
  animation: fadeIn 0.5s forwards;
}

.inner-project img {
  border-radius: 0px;
}

.line-container {
  position: absolute;
  z-index: 9999;
  pointer-events: none;
  mix-blend-mode: difference;
  width: 100%;
  height: 100%;
}

.project-title {
  text-align: left;
  font-size: 5.2em;
  font-family: 'arrayregular';
  white-space: pre-line;
  margin-bottom: 0.3em;
}

.project-info {
  opacity: 0;
  margin-top: 0%;
  padding: 1vh;
  overflow: hidden;
  animation-delay: 3.5s;
  animation: fadeIn 3s forwards;
}

.hidden {
  height: 0;
  display: none;
}

.visible {
  display: block;
  height: auto; 
}

.flex {
  display: flex;
}

.project-description {
  margin-top: 1em;
  padding-left: 1em;
  padding-bottom: 1em;
  color: black;
  font-size: 2em;
  text-align: left;
  font-family: "Hiragino Kaku Gothic ProN W3", sans-serif;
  width: 95%;
  display: hidden;
  white-space: pre-wrap;
}

.project-info-text-container {
  margin-top: 0.7em;
  padding-bottom: 1em;
  color: black;
  font-size: 2.3em;
  font-family: "Hiragino Kaku Gothic ProN W3", sans-serif;
  width: 81%;
  display: flex;
}

.project-info-table {
  display: block;
  overflow:hidden;
  min-width: 70%;
  border-collapse: collapse; 
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-top: none; 
  border-bottom-right-radius: 0.75em;
}

.project-info-table.open {
  transform-origin: top left;
  animation-delay: 5s;
  animation: change-height 0.32s forwards;
}

.project-info-row {
  border-bottom: 1px solid black;
  width: 100%;
}

.project-info-row:last-child {
  border-bottom: none;
}

@keyframes change-height {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

.project-info-label-cell {
  color: white;
  background-image: url('../../data/divider2.svg');
  background-repeat: no-repeat;
  background-size: auto 120%;
  background-clip: auto;
  padding-left: 0.4em;
  padding-right: 2em;
  display: flex;
  z-index: 0;
}

.project-info-text-year-column {
  flex: 1;
  text-align: right;
}

.project-info-value-cell {
  width: 100%;
  text-align: right;
  padding-right: 1em;
}

@media (min-width: 1000px) {
  .project-title {
    font-size: 3em;
  }

  .project-info-text-container {
    font-size: 1.2em;
    margin-top: 0.85em;
  }

  .project-description {
    font-size: 0.8em;
  }

  .project-info-table {
    min-width: 60%;
    max-width: 70%;
  }

  .project-info-text-year-column {
    margin-top: 0.2em;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .project-title {
    font-size: 3em;
  }

  .project-info-text-container {
    font-size: 1.15em;
    margin-top: 0.9em;
  }

  .project-description {
    font-size: 1em;
  }

  .project-info-table {
    min-width: 50%;
    max-width: 60%;
  }

  .project-info-text-year-column {
    margin-top: 0.25em;
  }
}

.inner-project.margin-change {
  animation: marginChange 0.5s forwards;
}

.inner-project.margin-revert {
  animation: marginRevert 0.5s forwards;
}

.inner-project.margin-hover {
  animation: marginHover 0.5s forwards;
}

.project-info.fade-in {
  animation-delay: 0.5s;
  animation: fadeIn 0.5s forwards;
}

.project-info.fade-out {
  animation-delay: 0.5s;
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes marginChange {
  from {
    margin-top: var(--margin-top-close);
    margin-bottom: var(--margin-bottom-close);
  }
  to {
    margin-top: var(--margin-top-open);
    margin-bottom: var(--margin-bottom-open);
  }
}

@keyframes marginHover {
  from {
    margin-top: var(--margin-top-close);
    margin-bottom: var(--margin-bottom-close);
  }
  to {
    margin-top: var(--margin-top-hover);
    margin-bottom: var(--margin-bottom-hover);
  }
}

@keyframes marginRevert {
  to {
    margin-top: var(--margin-top-close);
    margin-bottom: var(--margin-bottom-close);
  }
}
