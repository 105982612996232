html,body {
    margin: 0;
    height:100%;
    user-select: none;
    scrollbar-gutter: stable; 
    -webkit-overflow-scrolling: touch;
}

img {
  height: auto;
  width: 100%;
  pointer-events: none;
  user-drag: none;  
  user-select: none;
}

button {
  cursor: pointer;
}

video {
  height: auto;
  width: 100%;
  user-drag: none;  
  user-select: none;
}

#canvasDiv {
    position: fixed;
    width:100%;
    height:100%;
    z-index:-1;
}

.cover-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1; /* Ensure it covers the canvas */
}

@font-face {
    font-family: 'arrayregular';
    src: url('data/array.woff2') format('woff2'),
         url('data/array.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

#header {
  position: sticky;
  top:0;
  background: black;
  color: white;
  text-align: center;
  font-size: 5em;
  height: 2em;
  z-index: 9999;
}

#projectMask {
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  height: 100%;
  width: 100%;
  background-size: 100% 130%;
  z-index: 1;
  display: block;
}

.gradient-background {
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%);
}

.white-background{
  background: white;
}


.wipe {
  animation: wipeAnimation 2s forwards;
}

@keyframes wipeAnimation {
  0% {
    background-position-y: -100vh;
  }
  50%{

  }
  100% {
    background-position-y: 100vh;
  }
}

#header img {
  height: 100%;
  width: auto;
  filter: invert(1);
}

@keyframes strobe {
  0% {
    filter: invert(0);
  }
  50% {
    filter: invert(1);
  }
  100% {
    filter: invert(0);
  }
}

.strobing {
  animation: strobe 0.15s;
  animation-iteration-count: 10; 
}

.row:after {
  display: table;
  clear: both;
}

.column {
  position: relative;
  margin: 0 auto; 

  min-width: 33vh;
  max-width: 60vh;

  height: 100vh;
  display: flex;
  flex-direction: column;

  vertical-align: top; 
}

#projects {
  background: rgba(255, 255, 255, 0.8);
}

.trail {
  color: white;
  align-content: center;
  margin-top: 20%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1000px) {
  #header {
    font-size: 2em;
    height: 2em;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #header {
    font-size: 2.5em;
    height: 2.5em;
  }
}